import {
  Card,
  Heading,
  Icon,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { DragHandleMinor } from "@shopify/polaris-icons";
import * as React from "react";
import { Link } from "react-router-dom";
import "./BarsListItem.css";
import barTypes from "../../../barTypes";

export interface BarsListItemProps {
  bar: any;
}

const BarsListItem: React.FC<BarsListItemProps> = (props) => {
  const { bar } = props;
  let { name, barType } = bar;

  return (
    <Link
      style={{ color: "inherit", textDecoration: "none" }}
      to={`/banners/edit/${bar.id}`}
    >
      <Card>
        <Card.Section>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative", left: "-10px" }}>
                <Icon color="inkLightest" source={DragHandleMinor} />
              </div>
              {/* <Switch height={23} width={48} onChange={event => {}} checked={true} className="react-switch" /> */}
              <div className="bar-list-item___content">
                <div className="bar-icon">
                  <Icon
                    color="inkLightest"
                    source={barTypes[barType as keyof typeof barTypes].icon}
                  />
                </div>
                <TextContainer>
                  <Heading>{name}</Heading>
                  <TextStyle variation="subdued">
                    {barTypes[barType as keyof typeof barTypes].title}
                  </TextStyle>
                </TextContainer>
              </div>
            </div>
            <div className="bar-status">
              <span className="active-icon">
                <svg
                  fill="#108043"
                  viewBox="0 0 20 20"
                  focusable="false"
                  aria-hidden="true"
                >
                  <circle fill="#fff" cx="10" cy="10" r="9"></circle>
                  <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m2.293-10.707L9 10.586 7.707 9.293a1 1 0 1 0-1.414 1.414l2 2a.997.997 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414"></path>
                </svg>
              </span>
              <span className="active-text">Active</span>
            </div>
          </div>
        </Card.Section>
      </Card>
    </Link>
  );
};

export default BarsListItem;
