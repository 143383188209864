import { Context } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { Card, Page } from "@shopify/polaris";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Wrapper from "../../cw-tools/frontend/Components/Wrapper";
import BarTypeList from "../../components/BarTypeList/BarTypeList";
import TemplateList from "./TemplateList";

const NewBarPage: React.FC<RouteComponentProps> = (props) => {
  const [selectedBarType, setSelectedBarType] = React.useState("");
  const app = React.useContext(Context);
  // @ts-ignore
  const redirect = Redirect.create(app);

  return (
    <Wrapper title="Add New Banner">
      <Page
        // narrowWidth
        breadcrumbs={[
          {
            content: "All Banners",
            onAction: () => {
              redirect.dispatch(Redirect.Action.APP, "/banners");
            },
          },
        ]}
        title="Create New Banner"
        subtitle="Select from multiple different types of banners to start from scratch or select from pre built templates"
      >
        <Card>
          <BarTypeList selectedBarType={selectedBarType} setSelectedBarType={setSelectedBarType} />
          {selectedBarType ? <TemplateList selectedBarType={selectedBarType} /> : ""}
        </Card>
      </Page>
    </Wrapper>
  );
};

export default NewBarPage;
