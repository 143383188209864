import { Context } from "@shopify/app-bridge-react";
import { Page } from "@shopify/polaris";
import * as React from "react";
import { RouteChildrenProps } from "react-router-dom";
import Wrapper from "../../cw-tools/frontend/Components/Wrapper";
import BarsPageContainer from "./BarsPageContainer";
import { Redirect } from "@shopify/app-bridge/actions";

function BarsPage(props: RouteChildrenProps) {
  const app = React.useContext(Context);
  // @ts-ignore
  const redirect = Redirect.create(app);
  return (
    <Wrapper title="All Banners">
      <Page
        title="All Banners"
        primaryAction={{
          content: "Add New",
          onAction: () => {
            redirect.dispatch(Redirect.Action.APP, "/banners/new");
          },
        }}
      >
        <BarsPageContainer />
      </Page>
    </Wrapper>
  );
}

export default BarsPage;
