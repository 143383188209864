import * as React from "react";
import { ContentSettingsProps } from "../ContentSettings";
import { FormLayout, TextField } from "@shopify/polaris";
import MessageField from "./MessageField";

export interface CouponTypeSettingsProps extends ContentSettingsProps {}

const CouponTypeSettings: React.SFC<CouponTypeSettingsProps> = props => {
  const { bar, handleChange } = props;
  return (
    <FormLayout>
      <MessageField bar={bar} handleChange={handleChange} />
      <TextField label="Coupon Code" onChange={value => handleChange({ couponCode: value })} value={bar.couponCode} />
    </FormLayout>
  );
};

export default CouponTypeSettings;
