import * as React from "react";
import { Card, TextField, ChoiceList, FormLayout, Layout } from "@shopify/polaris";
import CountrySelect from "./CountrySelect";
import RenderIf from "../../cw-tools/frontend/Components/RenderIf";
import { shouldShowBasedOnBarType } from "./shouldShowBasedOnBarType";

export interface DisplaySettingsProps {
  handleChange: (partialNewState: any) => void;
  bar: any;
}

const DisplaySettings: React.SFC<DisplaySettingsProps> = ({ bar, handleChange }) => {
  return (
    <RenderIf condition={shouldShowBasedOnBarType({ barType: bar.barType, sectionName: "display" })}>
      <Layout.AnnotatedSection
        title="Display Settings"
        description="Decide which parts of your store should the banner display in and how should it behave"
      >
        <Card sectioned>
          <FormLayout>
            <CountrySelect bar={bar} handleChange={handleChange} />

            <ChoiceList
              title="Where to display the bar"
              onChange={(values) => {
                const [value] = values;
                if (value === "homePage") {
                  handleChange({ showOnName: value, showOnRegexSource: "" });
                } else if (value === "allPages") {
                  handleChange({ showOnName: value, showOnRegexSource: "" });
                } else {
                  handleChange({ showOnName: value, showOnRegexSource: "" });
                }
              }}
              choices={[
                { label: "All pages", value: "allPages" },
                { label: "Home page", value: "homePage" },
                { label: "Show if page's URL exactly matches this URL", value: "urlMatch" },
                { label: "Show if page's URL contains this string in URL", value: "stringMatch" },
              ]}
              selected={bar.showOnName}
            />

            {bar.showOnName !== "allPages" && bar.showOnName !== "homePage" && (
              <TextField
                label={
                  bar.showOnName === "urlMatch"
                    ? "Enter the url of page/product/collection"
                    : "Enter the text to match in url"
                }
                value={bar.showOnRegexSource}
                helpText={
                  bar.showOnName === "urlMatch"
                    ? "Eg. If you want to show the app only on https://yourstore.com/collections/tshirts enter '/collections/tshirts'"
                    : "Eg. If you want to show the app on all collections https://yourstore.com/collections enter '/collections'"
                }
                onChange={(value) => {
                  handleChange({
                    showOnRegexSource: value,
                  });
                }}
              />
            )}
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </RenderIf>
  );
};

export default DisplaySettings;
