import * as React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ManageResourceItem from "./BarsListItem";

export interface BarsListProps {
  bars: any;
  handleChange: (value: any, stateName: string) => void;
}

const getListStyle = (isDraggingOver: boolean) => ({
  width: "100%",
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 10px 0`,

  // styles we need to apply on drag-able
  ...draggableStyle,
});

const BarsList: React.FC<BarsListProps> = ({ bars, handleChange }) => {
  function immutablySwapItems<T>(
    items: Array<T>,
    firstIndex: number,
    secondIndex: number
  ) {
    // Constant reference - we can still modify the array itself
    const results = items.slice();
    const firstItem = items[firstIndex];
    results[firstIndex] = items[secondIndex];
    results[secondIndex] = firstItem;

    return results;
  }

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = immutablySwapItems(
      bars,
      result.source.index,
      result.destination.index
    );

    handleChange(items, "bars");
    // this.props.handleState("selectedPlatforms", items);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {bars.map((bar: any, index: number) => (
              <Draggable key={bar.id} draggableId={bar.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <ManageResourceItem key={bar.id} bar={bar} />
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default BarsList;
