import { Context, Modal } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { Layout } from "@shopify/polaris";
import { gql } from "apollo-boost";
import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import getShopName from "../../cw-tools/frontend/helpers/getShopName";
import { rgbToHex } from "../../cw-tools/frontend/helpers/rgbToHex";
import AnimationSettings from "./AnimationSettings";
import BarTypePicker from "./BarTypePicker";
import ContentSettings from "./ContentSettings";
import DisplaySettings from "./DisplaySettings";
import NameSettings from "./NameSettings";
import StylingSettings from "./StylingSettings";
import ShopifySaveBar from "../../cw-tools/frontend/Components/ShopifySaveBar";
import PreviewSection from "../../cw-tools/frontend/Components/PreviewSection/PreviewSection";
import { BARS } from "../BarsPage/BarsPageContainer";
import GeneralSettings from "./GeneralSettings";
import UpgradePlanModal from "../../cw-tools/frontend/Components/UpgradePlanModal";
import { Toast } from "@shopify/app-bridge/actions";
import { useHistory } from "react-router";
import { BAR } from "./BarQuery";

const UPSERT_ONE_BAR = gql`
  mutation updateOneAnnouncementBarTemp(
    $barUpdate: BarUpdateInput!
    $barCreate: BarCreateInput!
    $id: String!
  ) {
    upsertOneBar(where: { id: $id }, create: $barCreate, update: $barUpdate) {
      id
      merchant
      position
      haveCloseButton
      closeButtonColor {
        r
        g
        b
        a
      }
      sticky
      paddingTop
      paddingRight
      paddingLeft
      paddingBottom
      buttonBorderRadius
      buttonFontWeight
      justify
      backgroundImage
      fontFamily
      fontWeight
      fontSize
      buttonFontFamily
      buttonFontSize
      name
      message
      barType
      backgroundColor {
        r
        g
        b
        a
      }
      showOnName
      showOnRegexSource
      textColor {
        r
        g
        b
        a
      }
      buttonColor {
        r
        g
        b
        a
      }
      buttonTextColor {
        r
        g
        b
        a
      }
      order
      entranceAnimation
      exitAnimation
      attentionAnimation
      attentionAnimationDelay
      announcementDismissText
      linkUrl
      linkButtonText
      linkNewTab
      socialPlatformList
      countries
      goalPrice
      belowGoalMessage
      aboveGoalMessage
      couponCode
      buttonBorderColor {
        r
        g
        b
        a
      }
      buttonBorderType
      buttonBorderWidth
    }
  }
`;

const COUNT_BARS = gql`
  query barsCount {
    barsCount
  }
`;

const DELETE_ONE_BAR = gql`
  mutation deleteOneBar($id: String) {
    deleteOneBar(where: { id: $id }) {
      id
    }
  }
`;

export interface BarUpsertFormProps {
  bar: any;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (i: boolean) => void;
}

const validation = (formState: Record<string, any>) => {
  if (!formState.message) {
    return { message: "Message is not filled" };
  }

  if (formState.barType === "CART_GOAL") {
    if (!formState.aboveGoalMessage) {
      return { message: "Above Gaol Message is not filled" };
    } else if (!formState.belowGoalMessage) {
      return { message: "Below Gaol Message is not filled" };
    }
  }

  if (formState.barType === "COUPON") {
    if (!formState.couponCode) {
      return { message: "Coupon Code is not filled" };
    }
  }
};

const BarUpsertForm: React.FC<BarUpsertFormProps> = ({
  bar,
  setIsDeleteModalOpen,
  isDeleteModalOpen,
}) => {
  const app = React.useContext(Context);
  const history = useHistory();
  if (app === null) throw new Error("Can;t find the app context");
  const redirect = Redirect.create(app);
  // don't wait for loading
  const { data } = useQuery(COUNT_BARS);

  // Form state to save to the database
  const [formState, _setFormState] = React.useState({
    ...bar,
    hasChanged: false,
  });

  const [showProModal, setShowProModal] = React.useState(false);

  const [deleteBar, { loading: deleting }] = useMutation(DELETE_ONE_BAR, {
    variables: { id: formState.id },
    refetchQueries: [{ query: BARS }],
    awaitRefetchQueries: true,
  });
  // Function to adjust state for sending to the mutation above
  const barVariables = () => {
    // Remove trash from state
    const { hasChanged, id, __typename, ...sanitizedFormState } = formState;

    // Add merchant property to sanitized form state
    sanitizedFormState.merchant = getShopName();

    // Return the data but convert rgb to hex first
    return {
      ...sanitizedFormState,
      // order: config.announcementBars.length,
      countries: formState.countries,
      order: data ? data.barsCount + 1 : 10,
      backgroundColor: rgbToHex(formState.backgroundColor),
      textColor: rgbToHex(formState.textColor),
      buttonColor: rgbToHex(formState.buttonColor),
      buttonTextColor: rgbToHex(formState.buttonTextColor),
      closeButtonColor: rgbToHex(formState.closeButtonColor),
      buttonBorderColor: rgbToHex(formState.buttonBorderColor),
    };
  };

  const [upsertOneBar] = useMutation(UPSERT_ONE_BAR);

  function setFormState(partialFormState: typeof formState) {
    _setFormState({ ...formState, ...partialFormState, hasChanged: true });
  }

  async function handleSave() {
    const refetchQueries: Array<any> = [{ query: BARS }];
    if (bar.id) {
      refetchQueries.push({ query: BAR, variables: { id: formState.id } });
    }

    try {
      const result = await upsertOneBar({
        refetchQueries,
        variables: {
          barUpdate: barVariables(),
          barCreate: barVariables(),
          id: bar.id || "",
        },
      });

      _setFormState({ ...result.data.upsertOneBar, hasChanged: false });
      redirect.dispatch(Redirect.Action.APP, "/banners");
    } catch (error) {
      let message = (error as any).message;
      if (message.includes("NOT_PRO")) {
        setShowProModal(true);
      } else {
        // @ts-ignore
        const toastNotice = Toast.create(app, {
          message: "Error occured while saving" + message,
          duration: 5000,
        });
        toastNotice.dispatch(Toast.Action.SHOW);
      }
      // throw to reject the promise
      throw new Error(message);
    }
  }

  function handleDiscard() {
    _setFormState({ ...bar, hasChanged: false });
  }

  return (
    <ShopifySaveBar
      disableSubmit={Boolean(validation(formState))}
      successMessage={bar?.id ? "Bar Edited" : "Bar Added"}
      handleSave={handleSave}
      handleDiscard={handleDiscard}
      formState={formState}
    >
      <>
        {console.log(formState)}
        {/* LOADING ANIMATE CSS for preview */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
        />
        <PreviewSection
          style={{ width: "100%", display: "block" }}
          options={{ developmentMode: true, targetId: "superbar-codeword" }}
          formState={{ bars: [formState] }}
        />
        <Layout>
          <BarTypePicker
            handleChange={setFormState}
            barType={formState.barType}
          />
          <NameSettings handleChange={setFormState} bar={formState} />
          <ContentSettings handleChange={setFormState} bar={formState} />
          <GeneralSettings handleChange={setFormState} bar={formState} />
          <StylingSettings handleChange={setFormState} bar={formState} />
        </Layout>
        <Layout>
          <DisplaySettings handleChange={setFormState} bar={formState} />
          <AnimationSettings handleChange={setFormState} bar={formState} />
        </Layout>
        <UpgradePlanModal
          active={showProModal}
          resetFunction={() => {
            handleDiscard();
            setShowProModal(false);
          }}
          handleClose={() => {
            setShowProModal(false);
          }}
        />
      </>
      {isDeleteModalOpen && (
        <Modal
          title="Are you sure?"
          message="Are you sure you want to delete this banner? This can’t be undone."
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          secondaryActions={[
            {
              onAction: () => {
                setIsDeleteModalOpen(false);
              },
              content: "Cancel",
            },
          ]}
          primaryAction={{
            disabled: deleting,
            destructive: true,
            content: "Delete Banner",
            async onAction() {
              _setFormState({ ...formState, hasChanged: false });
              deleteBar().then(() => {
                setIsDeleteModalOpen(false);
                history.push("/banners");
              });
            },
          }}
        />
      )}
    </ShopifySaveBar>
  );
};

export default BarUpsertForm;
