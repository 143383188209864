import { Card, SkeletonBodyText } from "@shopify/polaris";
import { gql } from "apollo-boost";
import * as React from "react";
import { useQuery } from "react-apollo";
import Bars from "./Bars/Bars";
import ResourceNotFound from "../../cw-tools/frontend/Components/ResourceNotFound";

export const BARS = gql`
  query bars {
    bars {
      id
      barType
      name
      merchant
      order
    }
  }
`;

export interface BarsPageContainerProps {}

const BarsPageContainer: React.FC<BarsPageContainerProps> = () => {
  const { data, loading } = useQuery(BARS);

  if (loading) {
    return (
      <>
        <Card>
          <Card.Section>
            <SkeletonBodyText lines={2} />
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <SkeletonBodyText lines={2} />
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <SkeletonBodyText lines={2} />
          </Card.Section>
        </Card>
      </>
    );
  }

  // Render empty state

  if (data) {
    if (data.bars.length > 0) {
      return <Bars bars={data.bars} />;
    } else {
      return (
        <ResourceNotFound
          notFoundHeading="No Banners Found"
          notFoundSubheading="Get started by creating your first banner"
        />
      );
    }
  }

  return <div>An Error Occured</div>;
};

export default BarsPageContainer;
