import * as React from "react";
import { useContext, useEffect } from "react";
import { Context } from "@shopify/app-bridge-react";
import {
  ContextualSaveBar,
  Toast,
  Redirect,
} from "@shopify/app-bridge/actions";

export interface ShopifySaveBarProps {
  formState: any;
  disableSubmit?: boolean;
  successMessage?: string;
  successRedirectUri?: string;
  handleDiscard: () => void;
  children: React.ReactNode;
  handleSave: () => Promise<void>;
}

const ShopifySaveBar: React.FC<ShopifySaveBarProps> = (props) => {
  const {
    formState,
    children,
    handleDiscard,
    handleSave,
    successRedirectUri,
    disableSubmit = false,
    successMessage = "Saved",
  } = props;

  const app = useContext(Context);

  if (app === null) throw new Error("Can;t find the app context");

  const saveBarOptions = {
    saveAction: {
      disabled: disableSubmit,
      loading: false,
    },
    discardAction: {
      disabled: false,
      loading: false,
      discardConfirmationModal: true,
    },
  };

  const saveBarInstance = React.useMemo(
    () => ContextualSaveBar.create(app, saveBarOptions),
    []
  );

  useEffect(() => {
    // Do Something with discard action
    saveBarInstance.subscribe(ContextualSaveBar.Action.DISCARD, function () {
      handleDiscard();
      // Hide the contextual save bar
      saveBarInstance.dispatch(ContextualSaveBar.Action.HIDE);
    });

    saveBarInstance.subscribe(ContextualSaveBar.Action.SAVE, async () => {
      // Optionally, show a loading spinner while the save action is in progress
      saveBarInstance.set({ saveAction: { loading: true } });
      // Run Create Script Tag Mutation
      handleSave()
        .then(() => {
          // Stop Loading
          saveBarInstance.set({ saveAction: { loading: false } });

          // Hide the contextual save bar
          saveBarInstance.dispatch(ContextualSaveBar.Action.HIDE);
          // Show Toast
          const toastNotice = Toast.create(app, {
            message: successMessage,
            duration: 5000,
          });
          toastNotice.dispatch(Toast.Action.SHOW);

          // in-app redirect
          if (successRedirectUri) {
            const redirect = Redirect.create(app);
            redirect.dispatch(Redirect.Action.APP, successRedirectUri);
          }
        })
        .catch((e) => {
          // Stop Loading
          saveBarInstance.set({ saveAction: { loading: false } });

          // don't do anything in case of error
          console.log(e);
        });
    });

    return function unsubscribe() {
      saveBarInstance.unsubscribe();
    };
  }, [handleDiscard, handleSave, saveBarInstance]);

  useEffect(() => {
    if (formState.hasChanged) {
      saveBarInstance.dispatch(ContextualSaveBar.Action.SHOW);
    } else {
      saveBarInstance.dispatch(ContextualSaveBar.Action.HIDE);
    }
  }, [formState]);

  useEffect(() => {
    if (formState.hasChanged) {
      saveBarInstance.set(saveBarOptions);
    }
  }, [disableSubmit]);

  return <>{children}</>;
};

export default ShopifySaveBar;
