import { Card, FormLayout, Layout, RangeSlider, Select } from "@shopify/polaris";
import * as React from "react";
import RenderIf from "../../cw-tools/frontend/Components/RenderIf";
import { shouldShowBasedOnBarType } from "./shouldShowBasedOnBarType";

export interface AnimationSettingsProps {
  bar: any;
  handleChange: any;
}

const AnimationSettings: React.SFC<AnimationSettingsProps> = ({ bar, handleChange }) => {
  return (
    <RenderIf condition={shouldShowBasedOnBarType({ barType: bar.barType, sectionName: "animation" })}>
      <Layout.AnnotatedSection
        title="Animation Settings"
        description="Add Enter/Exit animations or attention grabbers with these settings"
      >
        <Card sectioned>
          <FormLayout>
            <Select
              label="Entrance Animation"
              options={[
                { label: "None", value: "none" },
                { label: "Fade In", value: "fadeIn" },
                { label: "Slide In", value: "slideIn" }
              ]}
              value={bar.entranceAnimation}
              onChange={newValue => handleChange({ entranceAnimation: newValue })}
            />
            <Select
              label="Exit Animation"
              options={[
                { label: "None", value: "none" },
                { label: "Fade Out", value: "fadeOut" },
                { label: "Slide Out", value: "slideOut" }
              ]}
              value={bar.exitAnimation}
              onChange={newValue => handleChange({ exitAnimation: newValue })}
            />

            <Select
              label="Attention Animation"
              options={[
                { label: "None", value: "none" },
                { label: "Shake", value: "shake" },
                { label: "Pulse", value: "pulse" },
                { label: "Wobble", value: "wobble" }
              ]}
              value={bar.attentionAnimation}
              onChange={newValue => handleChange({ attentionAnimation: newValue })}
            />
            {false && (
              <RangeSlider
                label="Attention Animation Delay"
                helpText="Duration of the attention animation loop (in miliseconds)"
                suffix="ms"
                min={1000}
                max={10000}
                step={1000}
                output
                value={bar.attentionAnimationDelay}
                onChange={value => {
                  handleChange({ attentionAnimationDelay: value });
                }}
              />
            )}
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </RenderIf>
  );
};

export default AnimationSettings;
