import * as React from "react";
import { countriesWithCode } from "../../countriesWithCode";
import Select from "react-select";
import * as _ from "lodash";

export interface CountrySelectProps {
  handleChange: (partialNewState: any) => void;
  bar: any;
}

function transformCountries(countries: typeof countriesWithCode) {
  return countries.map((country) => ({
    value: country.code,
    label: country.name,
  }));
}

function getCountriesByCode(codes: Array<string>) {
  const filteredCountry = countriesWithCode.filter((country) =>
    codes.includes(country.code)
  );
  return transformCountries(filteredCountry);
}

const countriesWithCodeOptions = transformCountries(countriesWithCode);

const CountrySelect: React.SFC<CountrySelectProps> = ({
  bar,
  handleChange,
}) => {
  const [selectValue, setSelectValue] = React.useState(
    getCountriesByCode(bar.countries)
  );

  React.useEffect(() => {
    const countryCodes = selectValue.map((val) => val.value);
    const isCodeEqual = _.isEqual(countryCodes, bar.countries);
    if (!isCodeEqual) {
      handleChange({ countries: countryCodes });
    }
  }, [selectValue, bar, handleChange]);

  return (
    <label>
      Countries in which bar is visible &nbsp;
      <br />
      <Select
        value={selectValue}
        placeholder="If not selected, shown to all countries"
        isMulti
        options={countriesWithCodeOptions}
        onChange={(option) => {
          if (option && Array.isArray(option)) {
            setSelectValue(option);
          }
        }}
      />
    </label>
  );
};

export default CountrySelect;
