import * as React from "react";
import { Card, Layout } from "@shopify/polaris";
import LinkTypeSettings from "./BarTypeSettings/LinkTypeSettings";
import AnnouncementTypeSettings from "./BarTypeSettings/AnnouncementTypeSettings";
import GoalTypeSettings from "./BarTypeSettings/GoalTypeSettings";
import CouponTypeSettings from "./BarTypeSettings/CouponTypeSettings";
import RenderIf from "../../cw-tools/frontend/Components/RenderIf";
import { shouldShowBasedOnBarType } from "./shouldShowBasedOnBarType";

export interface ContentSettingsProps {
  handleChange: (partialNewState: any) => void;
  bar: any;
}

const ContentSettings: React.SFC<ContentSettingsProps> = props => {
  const renderContentType = () => {
    switch (props.bar.barType) {
      case "ANNOUNCEMENT":
        return <AnnouncementTypeSettings {...props} />;
      case "CART_GOAL":
        return <GoalTypeSettings {...props} />;
      case "COUPON":
        return <CouponTypeSettings {...props} />;
      default:
        return <LinkTypeSettings {...props} />;
    }
  };

  return (
    <RenderIf condition={shouldShowBasedOnBarType({ barType: props.bar.barType, sectionName: "content" })}>
      <Layout.AnnotatedSection
        title="Content Settings"
        description="These settings are used to modify the content to be displayed in the banner"
      >
        <Card sectioned>{renderContentType()}</Card>
      </Layout.AnnotatedSection>
    </RenderIf>
  );
};

export default ContentSettings;
