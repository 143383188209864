import {
  DiscountCodeMajorMonotone,
  LinkMinor,
  MarketingMajorMonotone,
  ShipmentMajorMonotone
} from "@shopify/polaris-icons";

export default {
  ANNOUNCEMENT: {
    title: "Announcement Banner",
    subtitle: "Display an announcement to your visitors",
    icon: MarketingMajorMonotone,
    hideSections: ["buttonStyles"]
  },
  LINK: {
    title: "Link Banner",
    subtitle: "Link your users to a section of your store or the web",
    icon: LinkMinor,
    hideSections: []
  },
  CART_GOAL: {
    title: "Free Shipping Goal Banner",
    subtitle: "Display the visitor's progress towards unlocking free shipping or discount",
    icon: ShipmentMajorMonotone,
    hideSections: []
  },
  COUPON: {
    title: "Coupon Code Banner",
    subtitle: "Add a click to copy coupon code banner",
    icon: DiscountCodeMajorMonotone,
    hideSections: []
  },
  COUNTDOWN: {
    title: "",
    subtitle: "",
    icon: DiscountCodeMajorMonotone,
    hideSections: []
  },
  FORM: {
    title: "",
    subtitle: "",
    icon: DiscountCodeMajorMonotone,
    hideSections: []
  },
  SHARING: {
    title: "",
    subtitle: "",
    icon: DiscountCodeMajorMonotone,
    hideSections: []
  }
};
