import * as React from "react";
import { Layout } from "@shopify/polaris";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import BarsList from "./BarsList";
import ShopifySaveBar from "../../../cw-tools/frontend/Components/ShopifySaveBar";

const UPDATE_BARS_ORDER = gql`
  mutation updateBarsOrder($barIds: [ID!]) {
    updateBarsOrder(barIds: $barIds)
  }
`;

export interface BarsProps {
  bars: any;
}

function Bars({ bars }: BarsProps) {
  const [formState, _setFormState] = React.useState({
    bars: bars.sort((a: any, b: any) => a.order - b.order),
    hasChanged: false,
  });
  const [updateBarsOrder] = useMutation(UPDATE_BARS_ORDER, {
    variables: {
      barIds: formState.bars.map((bar: any) => bar.id),
    },
  });

  async function handleSave() {
    await updateBarsOrder();
  }

  function setFormState(bars: BarsProps["bars"]) {
    _setFormState({
      hasChanged: true,
      bars: bars,
    });
  }

  function handleDiscard() {
    _setFormState({ bars, hasChanged: false });
  }

  return (
    <ShopifySaveBar
      successMessage="Bars Order Changed"
      handleDiscard={handleDiscard}
      handleSave={handleSave}
      formState={formState}
    >
      <Layout>
        <Layout.Section fullWidth>
          <BarsList bars={formState.bars} handleChange={setFormState} />
        </Layout.Section>
      </Layout>
    </ShopifySaveBar>
  );
}

export default Bars;
