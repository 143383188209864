import * as React from "react";
import { FormLayout } from "@shopify/polaris";
import MessageField from "./MessageField";

export interface AnnouncementTypeSettingsProps {
  handleChange: (partialNewState: any) => void;
  bar: any;
}

const AnnouncementTypeSettings: React.FC<AnnouncementTypeSettingsProps> = props => {
  return (
    <FormLayout>
      <MessageField {...props} />
    </FormLayout>
  );
};

export default AnnouncementTypeSettings;
