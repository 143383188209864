import barTypes from "../../barTypes";

interface IArgs {
  sectionName: string;
  barType: keyof typeof barTypes;
}

export function shouldShowBasedOnBarType({ sectionName, barType }: IArgs) {
  const activeBarTypeConfig = barTypes[barType];
  const isHiddenInCurrentBarType = activeBarTypeConfig.hideSections.find(s => s === sectionName);

  if (isHiddenInCurrentBarType) {
    return false;
  }

  return true;
}
