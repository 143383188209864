import * as React from "react";
import { TextField } from "@shopify/polaris";

export interface MessageFieldProps {
  bar: Record<string, any>;
  handleChange: (i: Record<string, any>) => void;
}

const MessageField: React.SFC<MessageFieldProps> = ({ bar, handleChange }) => {
  return <TextField label="Message" value={bar.message} onChange={(value) => handleChange({ message: value })} />;
};

export default MessageField;
