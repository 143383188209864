import { gql } from "apollo-boost";

export const BAR = gql`
  query data($id: String!) {
    bar(where: { id: $id }) {
      id
      merchant
      position
      haveCloseButton
      closeButtonColor {
        r
        g
        b
        a
      }
      sticky
      paddingTop
      paddingRight
      paddingLeft
      paddingBottom
      buttonBorderRadius
      buttonFontWeight
      justify
      backgroundImage
      fontFamily
      fontWeight
      fontSize
      buttonFontFamily
      buttonFontSize
      name
      message
      barType
      backgroundColor {
        r
        g
        b
        a
      }
      showOnName
      showOnRegexSource
      textColor {
        r
        g
        b
        a
      }
      buttonColor {
        r
        g
        b
        a
      }
      buttonTextColor {
        r
        g
        b
        a
      }
      order
      entranceAnimation
      exitAnimation
      attentionAnimation
      attentionAnimationDelay
      announcementDismissText
      buttonPaddingTop
      buttonPaddingRight
      linkUrl
      linkButtonText
      linkNewTab
      socialPlatformList
      countries
      goalPrice
      belowGoalMessage
      aboveGoalMessage
      couponCode
      buttonBorderColor {
        r
        g
        b
        a
      }
      buttonBorderType
      buttonBorderWidth
    }
  }
`;
