import * as React from "react";

export interface RenderIfProps {
  condition: boolean;
  children: React.ReactNode;
}

const RenderIf: React.SFC<RenderIfProps> = ({ children, condition }) => {
  if (condition) {
    return <>{children}</>;
  }

  return <></>;
};

export default RenderIf;
