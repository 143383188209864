import { Card, Layout } from "@shopify/polaris";
import * as React from "react";
import BarTypeList from "../../components/BarTypeList/BarTypeList";
import RenderIf from "../../cw-tools/frontend/Components/RenderIf";
import { shouldShowBasedOnBarType } from "./shouldShowBasedOnBarType";

export interface BarTypePickerProps {
  barType: any;
  handleChange: (partialNewState: any) => void;
}

const BarTypePicker: React.FC<BarTypePickerProps> = ({ handleChange, barType }) => {
  return (
    <RenderIf condition={shouldShowBasedOnBarType({ barType, sectionName: "barType" })}>
      <Layout.AnnotatedSection
        title="Select Banner Type"
        description="Choose from the available banner types to display your message to visitors"
      >
        <Card sectioned>
          <BarTypeList setSelectedBarType={(barType: any) => handleChange({ barType })} selectedBarType={barType} />
        </Card>
      </Layout.AnnotatedSection>
    </RenderIf>
  );
};

export default BarTypePicker;
