import * as React from "react";
import image from "../assets/not-found.svg";
import { Stack, DisplayText, TextStyle } from "@shopify/polaris";

export interface ResourceNotFoundProps {
  notFoundHeading: string;
  notFoundSubheading: string;
}

const ResourceNotFound: React.SFC<ResourceNotFoundProps> = ({ notFoundHeading, notFoundSubheading }) => {
  return (
    <Stack vertical alignment="center">
      <Stack.Item>
        <img style={{ width: 250 }} src={image} alt="Not Found" />
      </Stack.Item>
      <Stack.Item>
        <DisplayText size="small">{notFoundHeading}</DisplayText>
      </Stack.Item>
      <Stack.Item>
        <TextStyle variation="subdued">{notFoundSubheading}</TextStyle>
      </Stack.Item>
    </Stack>
  );
};

export default ResourceNotFound;
