import { Card, Icon } from "@shopify/polaris";
import * as React from "react";
import barTypes from "../../barTypes";
import BarTypeItem from "../BarTypeItem/BarTypeItem";
import "./BarTypeList.css";

export interface BarTypeListProps {
  // selectedBarType: "LINK" | "ANNOUNCEMENT";
  selectedBarType: string;
  setSelectedBarType: any;
}

const BarTypeList: React.FC<BarTypeListProps> = ({
  selectedBarType,
  setSelectedBarType,
}) => {
  return (
    <Card.Section title="Select Bar Type">
      <div className="bar-type___list">
        <BarTypeItem
          active={selectedBarType === "ANNOUNCEMENT"}
          onClick={() => {
            setSelectedBarType("ANNOUNCEMENT");
          }}
          icon={
            <Icon color="inkLightest" source={barTypes.ANNOUNCEMENT.icon} />
          }
          title={barTypes.ANNOUNCEMENT.title}
          subtitle={barTypes.ANNOUNCEMENT.subtitle}
        />
        <BarTypeItem
          active={selectedBarType === "LINK"}
          onClick={() => {
            setSelectedBarType("LINK");
          }}
          icon={<Icon color="inkLightest" source={barTypes.LINK.icon} />}
          title={barTypes.LINK.title}
          subtitle={barTypes.LINK.subtitle}
        />

        <BarTypeItem
          active={selectedBarType === "CART_GOAL"}
          onClick={() => {
            setSelectedBarType("CART_GOAL");
          }}
          icon={<Icon color="inkLightest" source={barTypes.CART_GOAL.icon} />}
          title={barTypes.CART_GOAL.title}
          subtitle={barTypes.CART_GOAL.subtitle}
        />

        <BarTypeItem
          active={selectedBarType === "COUPON"}
          onClick={() => {
            setSelectedBarType("COUPON");
          }}
          icon={<Icon color="inkLightest" source={barTypes.COUPON.icon} />}
          title={barTypes.COUPON.title}
          subtitle={barTypes.COUPON.subtitle}
        />
      </div>
    </Card.Section>
  );
};

export default BarTypeList;
