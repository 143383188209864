import { Card, Checkbox, ChoiceList, FormLayout, Layout } from "@shopify/polaris";
import * as React from "react";
import ColorPicker from "../../components/ColorPicker";
import RenderIf from "../../cw-tools/frontend/Components/RenderIf";
import { shouldShowBasedOnBarType } from "./shouldShowBasedOnBarType";

export interface GeneralSettingsProps {
  handleChange: (partialNewState: any) => void;
  bar: any;
}

const GeneralSettings: React.SFC<GeneralSettingsProps> = ({ bar, handleChange }) => {
  return (
    <RenderIf condition={shouldShowBasedOnBarType({ barType: bar.barType, sectionName: "general" })}>
      <Layout.AnnotatedSection
        title="General Settings"
        description="These settings modify the overall behaviour of every banner"
      >
        <Card>
          <Card.Section title="Close button">
            <FormLayout>
              <Checkbox
                label="Include a close button"
                checked={bar.haveCloseButton}
                onChange={value => handleChange({ haveCloseButton: value })}
              />
              <ColorPicker
                label="Close Button Color"
                color={bar.closeButtonColor}
                handleChange={color => handleChange({ closeButtonColor: color })}
                stateItem="closeButtonColor"
              />
            </FormLayout>
          </Card.Section>
          <Card.Section title="Positioning">
            <FormLayout>
              <ChoiceList
                title="Position"
                choices={[
                  { label: "Top", value: "top" },
                  { label: "Bottom", value: "bottom" }
                ]}
                selected={bar.position}
                onChange={value =>
                  handleChange({
                    position: value[0]
                  })
                }
              />
              <Checkbox
                label="Enable the announcement bar to be sticky"
                checked={bar.sticky}
                onChange={value =>
                  handleChange({
                    sticky: value
                  })
                }
              />
            </FormLayout>
          </Card.Section>
        </Card>
      </Layout.AnnotatedSection>
    </RenderIf>
  );
};

export default GeneralSettings;
