import { Card, Heading, Icon, SkeletonBodyText, TextStyle, Button } from "@shopify/polaris";
import { CircleTickMajorMonotone } from "@shopify/polaris-icons";
import React from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import workIcon from "../cw-tools/frontend/Components/Dashboard/icons/work.png";
import { gql } from "apollo-boost";
import getShopName from "../cw-tools/frontend/helpers/getShopName";

const BARS_COUNT = gql`
  query firstBarsCount {
    barsCount
  }
`;

export default function SetupFirstAnnouncementBar() {
  const { data, loading, error } = useQuery(BARS_COUNT, {
    variables: {
      merchant: getShopName()
    }
  });

  const history = useHistory();

  if (loading) {
    return (
      <Card.Section>
        <SkeletonBodyText />
      </Card.Section>
    );
  }

  if (error) return <div>Error {error.message}</div>;

  if (data) {
    const isDone = data.barsCount >= 1;
    return (
      <Card.Section subdued={isDone}>
        <div className="current-app-action-item___wrapper">
          <div className="current-app-action-item___content">
            <div>
              <img className="current-app-action-item___icon" src={workIcon} alt="Codeword App Icon" />
            </div>
            <div>
              <TextStyle variation={isDone ? "subdued" : "strong"}>
                <Heading>Set up your first banner</Heading>
              </TextStyle>
              <TextStyle variation="subdued">
                <p>Start with setting up your first banner</p>
              </TextStyle>
            </div>
          </div>
          <div>
            {!isDone && (
              <Button
                onClick={() => {
                  history.push("/banners");
                }}
              >
                Create Banner
              </Button>
            )}
            {isDone && <Icon color="green" source={CircleTickMajorMonotone} />}
          </div>
        </div>
      </Card.Section>
    );
  }
  return <div></div>;
}
