import React from "react";
import { ChromePicker } from "react-color";

interface IColorPicker {
  stateItem: string;
  label: string;
  color: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
  handleChange: (value: any, stateItemName: any) => void;
}

class ColorPicker extends React.Component<IColorPicker> {
  state = {
    displayColorPicker: false,
    color: this.props.color
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleColorChange = (color: any) => {
    this.setState({ color: color.rgb });
  };
  handleColorChangeComplete = (color: any) => {
    this.props.handleChange(color.rgb, this.props.stateItem);
  };

  render() {
    const popover: any = {
      position: "absolute",
      zIndex: "32"
    };
    const cover: any = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px"
    };
    const buttonStyles = {
      margin: 0,
      padding: 0,
      background: "none",
      border: "none",
      fontSize: "inherit",
      lineHeight: "inherit",
      cursor: "pointer"
      // flex: "0 0 3.8rem",
    };
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button style={buttonStyles} onClick={this.handleClick}>
            <div
              style={{
                borderRadius: "3px",
                boxShadow: "inset 0 0 0 1px rgba(0,0,0,.07), inset 0 1px 3px 0 rgba(0,0,0,.15)",
                width: "50px",
                height: "3rem",
                backgroundColor: `rgba(${this.props.color.r},${this.props.color.g},${this.props.color.b},${this.props.color.a})`
              }}
            ></div>
          </button>
          <span style={{ marginLeft: 8 }}>{this.props.label}</span>
        </div>
        {this.state.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <ChromePicker
              color={this.state.color}
              onChange={this.handleColorChange}
              onChangeComplete={this.handleColorChangeComplete}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
