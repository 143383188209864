import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";
import * as React from "react";
import { shouldShowBasedOnBarType } from "./shouldShowBasedOnBarType";
import RenderIf from "../../cw-tools/frontend/Components/RenderIf";

export interface NameSettingsProps {
  handleChange: (partialNewState: any) => void;
  bar: any;
}

const NameSettings: React.SFC<NameSettingsProps> = ({ bar, handleChange }) => {
  return (
    <RenderIf condition={shouldShowBasedOnBarType({ barType: bar.barType, sectionName: "name" })}>
      <Layout.AnnotatedSection
        title="Info Settings"
        description="This setting will be used to identify the banner in the admin section and won't be visible to your visitors"
      >
        <Card subdued sectioned>
          <FormLayout>
            <TextField
              label="Banner Name"
              helpText="This setting is used to identify this banner while managing it in the admin area"
              value={bar.name}
              onChange={value => handleChange({ name: value })}
            />
          </FormLayout>
        </Card>
      </Layout.AnnotatedSection>
    </RenderIf>
  );
};

export default NameSettings;
