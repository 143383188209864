import * as React from "react";
import { Card, FormLayout, RangeSlider, Layout, Select } from "@shopify/polaris";
import ColorPicker from "../../components/ColorPicker";
import RenderIf from "../../cw-tools/frontend/Components/RenderIf";
import { shouldShowBasedOnBarType } from "./shouldShowBasedOnBarType";

export interface StylingSettingsProps {
  bar: any;
  handleChange: any;
}

const StylingSettings: React.SFC<StylingSettingsProps> = ({ bar, handleChange }) => {
  return (
    <RenderIf condition={shouldShowBasedOnBarType({ barType: bar.barType, sectionName: "styles" })}>
      <Layout.AnnotatedSection
        title="Design Settings"
        description="Use these settings to customize the look and feel of this banner to match your store's theme"
      >
        <Card>
          <RenderIf condition={shouldShowBasedOnBarType({ barType: bar.barType, sectionName: "contentStyles" })}>
            <Card.Section title="Banner Styles">
              <FormLayout>
                <ColorPicker
                  color={bar.backgroundColor}
                  handleChange={newValue => handleChange({ backgroundColor: newValue })}
                  label="Background Color"
                  stateItem="color"
                />
                <ColorPicker
                  color={bar.textColor}
                  handleChange={newValue => handleChange({ textColor: newValue })}
                  label="Font Color"
                  stateItem="color"
                />
                <RangeSlider
                  label="Font Size"
                  min={8}
                  max={24}
                  output
                  value={bar.fontSize}
                  onChange={value => {
                    handleChange({ fontSize: value });
                  }}
                />
                <RangeSlider
                  label="Padding Vertical"
                  min={0}
                  max={100}
                  output
                  value={bar.paddingTop}
                  onChange={value => {
                    handleChange({ paddingTop: value, paddingBottom: value });
                  }}
                />
                <RangeSlider
                  label="Padding Horizontal"
                  min={0}
                  max={100}
                  output
                  value={bar.paddingRight}
                  onChange={value => {
                    handleChange({ paddingRight: value, paddingLeft: value });
                  }}
                />

                <Select
                  label="Align Content"
                  options={[
                    { value: "center", label: "center" },
                    { value: "flex-start", label: "left" },
                    { value: "flex-end", label: "right" },
                    { value: "space-between", label: "in between" },
                    { value: "space-around", label: "space around" }
                  ]}
                  value={bar.justify}
                  onChange={newValue => handleChange({ justify: newValue })}
                />
              </FormLayout>
            </Card.Section>
          </RenderIf>

          <RenderIf condition={shouldShowBasedOnBarType({ barType: bar.barType, sectionName: "buttonStyles" })}>
            <Card.Section title="Button Styles">
              <FormLayout>
                <ColorPicker
                  color={bar.buttonColor}
                  handleChange={newValue => handleChange({ buttonColor: newValue })}
                  label="Button Backrgound Color"
                  stateItem="color"
                />
                <ColorPicker
                  color={bar.buttonTextColor}
                  handleChange={newValue => handleChange({ buttonTextColor: newValue })}
                  label="Button Font Color"
                  stateItem="color"
                />
                <RangeSlider
                  label="Button Font Size"
                  min={8}
                  max={24}
                  output
                  value={bar.buttonFontSize}
                  onChange={value => {
                    handleChange({ buttonFontSize: value });
                  }}
                />
                <RangeSlider
                  label="Button Font Weight"
                  helpText="This setting will have different effects on different fonts"
                  min={100}
                  max={700}
                  step={100}
                  output
                  value={bar.buttonFontWeight}
                  onChange={value => {
                    handleChange({ buttonFontWeight: value });
                  }}
                />
                <RangeSlider
                  label="Button Padding Vertical"
                  min={0}
                  max={100}
                  output
                  value={bar.buttonPaddingTop}
                  onChange={value => {
                    handleChange({ buttonPaddingTop: value });
                  }}
                />
                <RangeSlider
                  label="Button Padding Horizontal"
                  min={0}
                  max={100}
                  output
                  value={bar.buttonPaddingRight}
                  onChange={value => {
                    handleChange({ buttonPaddingRight: value });
                  }}
                />
                <RangeSlider
                  label="Button Border Radius"
                  min={0}
                  max={100}
                  output
                  value={bar.buttonBorderRadius}
                  onChange={value => {
                    handleChange({ buttonBorderRadius: value });
                  }}
                />
                <Select
                  label="Button border type"
                  value={bar.buttonBorderType}
                  options={[
                    { label: "hidden", value: "HIDDEN" },
                    { label: "dotted", value: "DOTTED" },
                    { label: "dashed", value: "DASHED" },
                    { label: "solid", value: "SOLID" },
                    { label: "double", value: "DOUBLE" }
                  ]}
                  onChange={selected => handleChange({ buttonBorderType: selected })}
                />
                {bar.buttonBorderType !== "HIDDEN" && (
                  <>
                    <ColorPicker
                      label="Button border color"
                      color={bar.buttonBorderColor}
                      handleChange={value => handleChange({ buttonBorderColor: value })}
                      stateItem="buttonBorderColor"
                    />
                    <br />
                    <RangeSlider
                      label="Button border width"
                      max={8}
                      min={0}
                      output
                      value={bar.buttonBorderWidth}
                      onChange={value => handleChange({ buttonBorderWidth: value })}
                    />
                  </>
                )}
              </FormLayout>
            </Card.Section>
          </RenderIf>
        </Card>
      </Layout.AnnotatedSection>
    </RenderIf>
  );
};

export default StylingSettings;
