import { Page } from "@shopify/polaris";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Wrapper from "../../cw-tools/frontend/Components/Wrapper";
import BarUpsertFormContainer from "./BarUpsertFormContainer";

const BarUpsertPage: React.FC<RouteComponentProps<{ id?: string; templateId: string }>> = props => {
  const barId = props.match.params.id;
  const templateId = props.match.params.templateId;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  return (
    <Wrapper title="Add New Banner">
      <Page
        primaryAction={{
          content: "Delete Banner",
          destructive: true,
          disabled: !barId,
          onAction: () => {
            setIsDeleteModalOpen(true);
          }
        }}
        title={barId ? "Edit Banner" : "Add New Banner"}
        fullWidth
      >
        <BarUpsertFormContainer
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          templateId={templateId}
          barId={barId}
        />
      </Page>
    </Wrapper>
  );
};

export default BarUpsertPage;
