import * as React from "react";
import { Route, Switch } from "react-router-dom";
import BarsPage from "./pages/BarsPage/BarsPage";
import BarUpsertPage from "./pages/BarUpsertPage/BarUpsertPage";
import Dashboard from "./cw-tools/frontend/Components/Dashboard/Dashboard";
import Login from "./cw-tools/frontend/Components/Login";
import NewBarPage from "./pages/NewBarPage/NewBarPage";

export interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <Dashboard {...props} />} />
      <Route exact path="/login" render={(props) => <Login {...props} />} />
      <Route
        exact
        path="/onboarding"
        render={(props) => <NewBarPage {...props} />}
      />
      <Route
        exact
        path="/banners/new/:templateId"
        render={(props) => <BarUpsertPage {...props} />}
      />
      <Route
        exact
        path="/banners"
        render={(props) => <BarsPage {...props} />}
      />
      <Route
        exact
        path="/banners/new"
        render={(props) => <NewBarPage {...props} />}
      />
      <Route
        exact
        path="/banners/edit/:id"
        render={(props) => <BarUpsertPage {...props} />}
      />
    </Switch>
  );
};

export default Routes;
