import * as React from "react";
import SetupFirstAnnouncementBar from "../SetupFirstAnnouncementBar";

export interface GettingStartedProps {}

const GettingStarted: React.SFC<GettingStartedProps> = () => {
  return <SetupFirstAnnouncementBar />;
};

export default GettingStarted;
