import * as React from "react";
import { FormLayout, TextField, Checkbox } from "@shopify/polaris";
import MessageField from "./MessageField";

export interface LinkTypeSettingsProps {
  handleChange: (partialNewState: any) => void;
  bar: any;
}

const LinkTypeSettings: React.FC<LinkTypeSettingsProps> = (props) => {
  const { bar, handleChange } = props;
  return (
    <FormLayout>
      <MessageField {...props} />

      <TextField
        helpText="leave blank for the whole bar to be clickable"
        label="Button Text"
        value={bar.linkButtonText}
        onChange={(value) =>
          handleChange({
            linkButtonText: value,
          })
        }
      />

      <TextField
        helpText="Link address to go after clicking the banner/button"
        label="Link URL"
        value={bar.linkUrl}
        onChange={(value) =>
          handleChange({
            linkUrl: value,
          })
        }
      />
      <Checkbox
        label="Open link in a new tab"
        checked={bar.linkNewTab}
        onChange={(value) =>
          handleChange({
            linkNewTab: value,
          })
        }
      />
    </FormLayout>
  );
};

export default LinkTypeSettings;
