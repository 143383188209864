import * as React from "react";
import { Card } from "@shopify/polaris";
import { templates } from "../../utils/templates";
import { Link } from "react-router-dom";
import PreviewSection from "../../cw-tools/frontend/Components/PreviewSection/PreviewSection";

export interface TemplateListProps {
  // selectedBarType: "LINK" | "ANNOUNCEMENT";
  selectedBarType: any;
}

const TemplateList: React.FC<TemplateListProps> = ({ selectedBarType }) => {
  return (
    <>
      <Card.Section title="Pre-Made Templates">
        {/* LOADING ANIMATE CSS for preview */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
        />
        {templates.map((template, index) => {
          if (template.state.barType === selectedBarType) {
            return (
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                key={index}
                to={`/banners/new/${index}`}
              >
                <div
                  style={{
                    margin: "16px 0",
                    width: "100%",
                    padding: "0",
                    background: "inherit",
                    border: "none",
                    display: "block",
                  }}
                  title="Click to use it"
                >
                  <PreviewSection
                    style={{ width: "100%", display: "block" }}
                    options={{
                      developmentMode: true,
                      targetId: `template-preview-${index}`,
                    }}
                    formState={{ bars: [template.state] }}
                  />
                </div>
              </Link>
            );
          }
          return null;
        })}
      </Card.Section>
    </>
  );
};

export default TemplateList;
