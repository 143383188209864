import * as React from "react";
import { TextField, FormLayout } from "@shopify/polaris";
import MessageField from "./MessageField";

export interface GoalSettingsProps {
  handleChange: (i: GoalSettingsProps["bar"]) => void;
  bar: Record<string, any>;
}

const GoalSettings: React.SFC<GoalSettingsProps> = props => {
  const { handleChange, bar } = props;

  return (
    <FormLayout>
      <MessageField {...props} />
      <TextField
        label="Free Shipping Goal Amount"
        type="number"
        helpText="Cart value amount needed for free shipping"
        onChange={value => handleChange({ goalPrice: Number(value) })}
        value={bar.goalPrice}
        error={bar.goalPrice ? "" : "Required"}
      />

      <TextField
        label="Below Goal Message"
        helpText="Shown when cart value is not empty but below the goal"
        onChange={value => handleChange({ belowGoalMessage: value })}
        value={bar.belowGoalMessage}
        error={bar.belowGoalMessage ? "" : "Required"}
      />

      <TextField
        label="Above Goal Message"
        helpText="Shown when cart value is above the goal"
        onChange={value => handleChange({ aboveGoalMessage: value })}
        value={bar.aboveGoalMessage}
        error={bar.aboveGoalMessage ? "" : "Required"}
      />
    </FormLayout>
  );
};

export default GoalSettings;
