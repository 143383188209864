import {
  Card,
  Heading,
  Icon,
  SkeletonBodyText,
  TextStyle,
  Button,
} from "@shopify/polaris";
import { CircleTickMajorMonotone } from "@shopify/polaris-icons";
import { gql } from "apollo-boost";
import React from "react";
import { useQuery, useMutation } from "react-apollo";
import toggleOnIcon from "../icons/toggle-on.png";

const CONFIG = gql`
  query getPluginEnabled {
    config {
      id
      merchant
      pluginEnabled
    }
  }
`;

const TOGGLE_CONFIG = gql`
  mutation togglePluginEnabled {
    togglePluginEnabled {
      id
      merchant
      pluginEnabled
    }
  }
`;

export function EnablePluginSection() {
  const { data, loading, error } = useQuery(CONFIG);
  const [toggleConfig, { loading: toggling }] = useMutation(TOGGLE_CONFIG);

  if (loading) {
    return (
      <Card.Section>
        <SkeletonBodyText />
      </Card.Section>
    );
  }

  if (error) return <div>Error {error.message}</div>;

  if (data) {
    const isDone = data.config?.pluginEnabled || false;
    return (
      <Card.Section subdued={isDone}>
        <div className="current-app-action-item___wrapper">
          <div className="current-app-action-item___content">
            <div>
              <img
                className="current-app-action-item___icon"
                src={toggleOnIcon}
                alt="Codeword App Icon"
              />
            </div>
            <div>
              <TextStyle variation={isDone ? "subdued" : "strong"}>
                <Heading>Enable App</Heading>
              </TextStyle>
              <TextStyle variation="subdued">
                <p>Enable app for it to start displaying on your shop</p>
              </TextStyle>
            </div>
          </div>
          {!isDone && (
            <Button
              onClick={() => {
                toggleConfig();
              }}
              // size="slim"
              loading={toggling}
            >
              Enable App
            </Button>
          )}
          {isDone && (
            <div>
              <Icon color="green" source={CircleTickMajorMonotone} />
            </div>
          )}
        </div>
      </Card.Section>
    );
  }
  return <div></div>;
}
