import * as React from "react";
import "./BarTypeItem.css";

export interface BarTypeItemProps {
  onClick: () => void;
  icon: any;
  title: string;
  subtitle: string;
  active?: boolean;
}

const BarTypeItem: React.FC<BarTypeItemProps> = ({
  onClick,
  icon,
  title,
  subtitle,
  active,
}) => {
  return (
    <button
      className={`bar-type-item___button ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <div className="bar-type-item___icon">{icon}</div>
      <div className="bar-type-item___content">
        <strong>{title}</strong>
        <span className="bar-type-item___subtitle">{subtitle}</span>
      </div>
    </button>
  );
};

export default BarTypeItem;
