import { Card, Layout, SkeletonBodyText } from "@shopify/polaris";
import * as React from "react";
import { useLazyQuery } from "react-apollo";
import { templates } from "../../utils/templates";
import { BAR } from "./BarQuery";
import BarUpsertForm from "./BarUpsertForm";

export interface BarUpsertFormContainerProps {
  barId?: String;
  templateId: String;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (i: boolean) => void;
}

const BarUpsertFormContainer: React.FC<BarUpsertFormContainerProps> = ({
  barId,
  templateId,
  ...restProps
}) => {
  const [getBar, { called, loading, error, data }] = useLazyQuery(BAR, {
    variables: { id: barId },
  });

  const getBarTemplate = () => {
    return templates[Number(templateId)].state;
  };

  React.useEffect(() => {
    if (barId) {
      getBar();
    }
  }, [barId, getBar]);

  if (loading || (barId && !called)) {
    return (
      <>
        <Card>
          <Card.Section>
            <SkeletonBodyText />
          </Card.Section>
        </Card>
        <br />
        <Layout>
          <Layout.Section oneThird>
            <SkeletonBodyText lines={4} />
          </Layout.Section>
          <Layout.Section>
            <Card>
              <Card.Section>
                <SkeletonBodyText lines={20} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </>
    );
  }
  if (error) return <p>Error </p>;

  return (
    <BarUpsertForm
      isDeleteModalOpen={restProps.isDeleteModalOpen}
      setIsDeleteModalOpen={restProps.setIsDeleteModalOpen}
      bar={data?.bar || getBarTemplate()}
    />
  );
};

export default BarUpsertFormContainer;
